// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-end-js": () => import("../src/pages/end.js" /* webpackChunkName: "component---src-pages-end-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pitch-generator-js": () => import("../src/pages/pitchGenerator.js" /* webpackChunkName: "component---src-pages-pitch-generator-js" */),
  "component---src-pages-storyboard-builder-js": () => import("../src/pages/storyboardBuilder.js" /* webpackChunkName: "component---src-pages-storyboard-builder-js" */)
}

