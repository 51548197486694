const initialState = {
  pitch: "",
  steps : [],
  distance : 0,
};

const SET_PITCH = 'SET_PITCH';
const SET_STEPS = 'SET_STEPS';
const SET_DISTANCE = 'SET_DISTANCE';

export const setPitch = content => ({
  type: SET_PITCH, content
});
export const setSteps = content => ({
  type: SET_STEPS, content
});
export const setDistance = content => ({
  type: SET_DISTANCE, content
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PITCH:
      return { ...state, pitch: action.content };
    case SET_STEPS:
      return { ...state, steps: [...action.content] };
    case SET_DISTANCE:
      return { ...state, distance: action.content };
    default:
      return state;
  }
};
